/* eslint-disable prefer-regex-literals */
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { finalize, take } from 'rxjs';
import { CaptchaUserModel, InitialSignupUserModel } from 'src/app/core/domain/auth/auth.model';
import { RegisterUseCase } from 'src/app/core/usecases/auth/register.usecase';
import { SetRegisterTokenUseCase } from 'src/app/core/usecases/auth/set-register-token.usecase';
import { MetaPhoneNumberOutput } from 'src/app/presentation/shared/components/meta-phone-number-field/meta-phone-number-field.component';
import { AUTH_CONSTS, EMAIL_REGULAR_EXPRESSION } from 'src/app/presentation/shared/constants';
import { PasswordValidatorUtility } from 'src/app/presentation/shared/utilities/password-validator.utility';
import { resolveUserLandingUtility } from 'src/app/presentation/shared/utilities/resolve-user-landing.utility';
// Ignoring the linting check because this comes from a Kotlin Library
import { NgClass, NgIf } from '@angular/common';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { getBotPoisonChallenge } from '@features/drivers/data/bot-poison';
import { navigateTo } from '@presentation/shared/router';
// @ts-ignore
import * as CountryResolver from '@taager-experience-shared/country-resolver';
// @ts-ignore
import { hazelnut } from '@mentoor.io/hazelnut-client';
import { trans } from '@mongez/localization';
import { parseError } from '@presentation/shared/error';
import { toastError } from '@presentation/shared/toast';
import { LoaderComponent } from 'app/presentation/shared/components/loader/loader.component';
import { environment } from 'environment';
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha';
import { AppTracker } from 'src/app/components/tracking/app-tracker';
import { IdentifyMixpanelInstanceUseCase } from 'src/app/core/usecases/analytics/identify-mixpanel-instance.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { regexValidator } from 'src/app/presentation/shared/utilities/regexValidator.utility';
import { GetFeatureFlagUsecase } from '../../../../../core/usecases/get-feature-flag.usecase';
import { MetaPhoneNumberFieldComponent } from '../../../../shared/components/meta-phone-number-field/meta-phone-number-field.component';
import {
  IS_SIGNUP_RECAPTCHA_ENABLED,
  REFERRAL_PROGRAM,
} from '../../../../shared/constants/feature-flags';
import {
  SharedNotificationComponent,
  SharedNotificationConfig,
} from '../../../shared/notification/shared.notification.component';
import { SignupStepsBaseComponent } from '../signup-steps-base.component';

@Component({
  templateUrl: './signup-landing.component.html',
  styleUrls: ['./signup-landing.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SharedNotificationComponent,
    FormsModule,
    ReactiveFormsModule,
    MetaPhoneNumberFieldComponent,
    NgClass,
    RecaptchaModule,
    MatSlideToggleModule,
    LoaderComponent,
  ],
})
export class SignupLandingComponent extends SignupStepsBaseComponent implements OnInit, OnDestroy {
  @Input() isRecaptchaEnabled = !environment.RECAPTCHA_BYPASS;

  @ViewChild('recaptcha')
  private _recaptcha: RecaptchaComponent;

  private _captchaResponse?: string = environment.RECAPTCHA_BYPASS_RESPONSE;

  public isLoading = false;

  public showPassword = false;

  public showRepeatedPassword = false;

  public authAssetsRelativePath = '/assets/img/auth/';

  public formSubmissionInProgress = false;

  public hasReferralCode = false;

  public isReferralProgramEnabled = true;

  public signupForm: UntypedFormGroup = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', [
        Validators.required,
        regexValidator(new RegExp(EMAIL_REGULAR_EXPRESSION), { validEmail: true }),
      ]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        PasswordValidatorUtility.containUpperAndLower({ containUpperAndLower: true }),
        PasswordValidatorUtility.containNumAndSpecialChar({ containNumAndChar: true }),
      ]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
      countryCode: new UntypedFormControl(''),
      referralCode: new UntypedFormControl(''),
      referralSlideToggle: new UntypedFormControl(false),
    },
    { validators: this.passwordMatchCheck.bind(this) },
  );

  public responseMessage: SharedNotificationConfig;

  public phonePrefix: string;

  public isReferralCodeNotExist = false;

  public referralSource = 'referral-code';

  constructor(
    private _registerUseCase: RegisterUseCase,
    private _changeDetectorRef: ChangeDetectorRef,
    private _setRegisterTokenUseCase: SetRegisterTokenUseCase,
    private _identifyMixpanelInstanceUseCase: IdentifyMixpanelInstanceUseCase,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _route: ActivatedRoute,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _appTracker: AppTracker,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    super();
  }

  ngOnInit(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'sign_up_page_load',
      payload: {
        Status: 'load signup page',
      },
    });
    this.checkIfRecaptchaIsEnabled();
    this.checkReferralProgramEnabled();
    this.getQueryParams();
  }

  getQueryParams(): void {
    this._route.queryParams.subscribe((queryParams) => {
      if (queryParams.referralCode) {
        this.hasReferralCode = true;
        this.referralSource = queryParams.src;
        this.signupForm.get('referralSlideToggle')!.patchValue(true);
        this.signupForm.get('referralCode')!.patchValue(queryParams.referralCode);
      }
    });
  }

  private checkIfRecaptchaIsEnabled(): void {
    this._getFeatureFlagUseCase
      .execute(IS_SIGNUP_RECAPTCHA_ENABLED)
      .pipe(take(1))
      .subscribe({
        next: (isEnabled) => {
          if (environment.RECAPTCHA_BYPASS) return;

          this.isRecaptchaEnabled = isEnabled;
        },
      });
  }

  public onRecaptchaResponse(response?: string): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'sign_up_recaptcha_response',
      payload: {
        data: response,
      },
    });

    this._captchaResponse = response;
  }

  public checkReferralProgramEnabled(): void {
    this._getFeatureFlagUseCase.execute(REFERRAL_PROGRAM).subscribe({
      next: (flag) => {
        this.isReferralProgramEnabled = flag;
      },
    });
  }

  public passwordMatchCheck(formGroup: UntypedFormGroup): { passwordNotMatch: boolean } | null {
    const { value: password } = formGroup.get('password') as { value: string };
    const { value: confirmPassword } = formGroup.get('confirmPassword') as { value: string };
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  public showHidePassword(): void {
    this.showPassword = !this.showPassword;
  }

  public phoneNumberChanges(ev: MetaPhoneNumberOutput): void {
    this.phonePrefix = ev.country.phoneNumPrefix.toString();
    this.signupForm.get('phoneNumber')!.patchValue(ev.phoneNumber);
    this.signupForm.get('phoneNumber')!.clearValidators();
    this.signupForm.get('phoneNumber')!.addValidators(Validators.required);
    this.signupForm.get('phoneNumber')!.addValidators(Validators.pattern(ev.country.phoneRegex));
    this.signupForm.get('countryCode')!.patchValue(ev.country.isoCode3);
  }

  toggleReferralInput(event: MatSlideToggleChange): void {
    this.hasReferralCode = !this.hasReferralCode;
    if (event.checked === true) {
      this.signupForm.get('referralCode')!.addValidators(Validators.required);
    } else {
      this.signupForm.get('referralCode')!.clearValidators();
      this.signupForm.get('referralCode')!.reset();
    }
  }

  public isSubmitButtonDisabled(): boolean {
    return (
      this.signupForm.invalid || this.formSubmissionInProgress || this._captchaResponse == null
    );
  }

  public async onSubmit(): Promise<void> {
    this.formSubmissionInProgress = true;
    this.isLoading = true;
    // reset response message
    this.responseMessage = undefined as any;

    const registerInput = this.buildRegisterInput();

    try {
      const botPoisonToken = await getBotPoisonChallenge();

      registerInput.bpResponse = botPoisonToken;

      this._registerUseCase
        .execute(registerInput)
        .pipe(
          take(1),
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (success) => {
            /**
             * So, as a new user, my new journey has to be identified under my account,
             * that is, under my ID.
             */
            this._appTracker.identifyUser();
            this._identifyMixpanelInstanceUseCase.execute();
            this.formSubmissionInProgress = false;
            this._logMixpanelEventUseCase.execute({
              eventName: 'continue_sign_up',
              payload: {
                Status: 'passed landing page',
                referralCodeSource: this.signupForm.get('referralCode')!.value
                  ? this.referralSource
                  : undefined,
              },
            });
            this.responseMessage = {
              msg: 'Please wait as we redirect you to OTP page!',
              status: 'success',
              iconMeta: {
                icon: `${this.authAssetsRelativePath}white-check-mark.svg`,
                position: 'before',
              },
            };
            this._commonChangeDetector();
            this._setRegisterTokenUseCase.execute(success.token);
          },
          error: (err) => {
            this.isLoading = false;
            this.formSubmissionInProgress = false;
            if (this.isRecaptchaEnabled) {
              this._recaptcha.reset();
            }

            this.responseMessage = {
              msg: err?.error?.description?.includes('Invalid Phone number')
                ? 'رقم الهاتف غير صحيح'
                : AUTH_CONSTS.REGISTER_ERRORS_MAP.get(err?.error?.errorCode) || parseError(err),
              status: 'error',
              iconMeta: {
                icon: `${this.authAssetsRelativePath}danger-white.svg`,
                position: 'before',
              },
            };

            this._commonChangeDetector();
            if (err?.status === 409) {
              this._logMixpanelEventUseCase.execute({
                eventName: 'sign_up_error_email_or_mobile_existed',
                payload: {
                  Status: 'error signup page already registered',
                },
              });
              if (err?.error?.errorCode === 'invalid-referral-code') {
                this.isReferralCodeNotExist = true;
              }
            } else if (err?.status === 500) {
              this._logMixpanelEventUseCase.execute({
                eventName: 'sign_up_error_number_invalid',
                payload: {
                  Status: 'error signup page invalid phone number',
                },
              });
            } else if (err?.status === 400) {
              this._logMixpanelEventUseCase.execute({
                eventName: 'sign_up_error_bad_request',
                payload: {
                  error: err.error,
                },
              });
            }
          },
        });
    } catch (error) {
      hazelnut.error(error, {
        botPoison: 'Failed to get bot poison token',
      });

      toastError(trans('auth.somethingWentWrongWithBp'));
    }
  }

  private buildRegisterInput(): InitialSignupUserModel {
    const userModel: InitialSignupUserModel = {
      email: this.signupForm.get('email')!.value,
      password: this.signupForm.get('password')!.value,
      phoneNumber: this.signupForm.get('phoneNumber')!.value.toString(),
      callingCode: this.phonePrefix,
      selectedMarket: CountryResolver.getCountryFromIsoCode2(
        resolveUserLandingUtility().code.toUpperCase(),
      ).isoCode3,
      referredByCode: this.signupForm.get('referralCode')!.value
        ? this.signupForm.get('referralCode')!.value
        : undefined,
    };

    if (this.isRecaptchaEnabled || environment.RECAPTCHA_BYPASS) {
      return {
        ...userModel,
        captchaResponse: this._captchaResponse,
      } as CaptchaUserModel;
    }

    return userModel;
  }

  private _commonChangeDetector(): void {
    this._changeDetectorRef.detectChanges();
  }

  public showHideRepeatedPassword(): void {
    this.showRepeatedPassword = !this.showRepeatedPassword;
  }

  get email(): AbstractControl {
    return this.signupForm.get('email')!;
  }

  get password(): AbstractControl {
    return this.signupForm.get('password')!;
  }

  onGoToLoginClicked(): void {
    const landingCountry = CountryResolver.getCountryFromIsoCode2(
      resolveUserLandingUtility().code.toUpperCase(),
    ).isoCode3;

    navigateTo(this.appURLs.LOGIN_V2_URL);

    this._logMixpanelEventUseCase.execute({
      eventName: 'register-go-to-login-click',
      payload: {
        landing_page_country: landingCountry,
      },
    });
  }
}
